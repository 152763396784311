<template>
  <div class="footer-wrapper">
    <footer class="footer4-footer7 thq-section-padding">
      <div class="footer4-max-width thq-section-max-width">
      <div class="footer4-content">
        <div class="footer4-logo"></div>
        <div class="footer4-links">
          <div class="thq-body-small" style="cursor: pointer;">
            <slot name="link4">
              <!--Default content for link4-->
              <div class="footer4-fragment3">
                <span class="footer4-text7">Contact Us</span>
              </div>
            </slot>
          </div>
        </div>
      </div>
      <div class="footer4-credits">
        <div class="thq-divider-horizontal"></div>
        <div class="footer4-row">
          <div class="footer4-container">
            <span class="thq-body-small">© 2024 Cargocode Ltd</span>
          </div>
          <div class="footer4-footer-links">
            <span @click="openPrivacyModal" class="footer4-text2 thq-body-small" style="cursor: pointer;">
              <slot name="privacyLink">
                <!--Default content for privacyLink-->
                <div class="footer4-fragment5">
                  <span class="footer4-text9">Privacy Policy</span>
                </div>
              </slot>
            </span>
            <span @click="openTermsModal" class="thq-body-small" style="cursor: pointer;">
              <slot name="termsLink">
                <!--Default content for termsLink-->
                <div class="footer4-fragment2">
                  <span class="footer4-text6">Terms and Conditions</span>
                </div>
              </slot>
            </span>
            <span @click="openCookiesModal" class="thq-body-small" style="cursor: pointer;">
              <slot name="cookiesLink">
                <!--Default content for cookiesLink-->
                <div class="footer4-fragment4">
                  <span class="footer4-text8">Cookies Policy</span>
                </div>
              </slot>
            </span>
          </div>
        </div>
      </div>
      </div>
    </footer>
    <PrivacyModal :is-open="showPrivacyModal" @close="closePrivacyModal" />
    <TermsModal :is-open="showTermsModal" @close="closeTermsModal" />
    <CookiesModal :is-open="showCookiesModal" @close="closeCookiesModal" />
  </div>
</template>

<script>
import PrivacyModal from './PrivacyModal.vue'
import TermsModal from './TermsModal.vue'
import CookiesModal from './CookiesModal.vue'

export default {
  name: 'Footer4',
  components: {
    PrivacyModal,
    TermsModal,
    CookiesModal
  },
  data() {
    return {
      showPrivacyModal: false,
      showTermsModal: false,
      showCookiesModal: false
    }
  },
  methods: {
    openPrivacyModal() {
      this.showPrivacyModal = true
    },
    closePrivacyModal() {
      this.showPrivacyModal = false
    },
    openTermsModal() {
      this.showTermsModal = true
    },
    closeTermsModal() {
      this.showTermsModal = false
    },
    openCookiesModal() {
      this.showCookiesModal = true
    },
    closeCookiesModal() {
      this.showCookiesModal = false
    }
  },
  props: {},
}
</script>

<style scoped>
.footer-wrapper {
  width: 100%;
}

.footer4-footer7 {
  width: 100%;
  height: auto;
  display: flex;
  overflow: hidden;
  position: relative;
  align-items: center;
  flex-shrink: 0;
  flex-direction: column;
  justify-content: center;
}
 
.footer4-max-width {
  gap: var(--dl-layout-space-threeunits);
  display: flex;
  align-items: center;
  flex-direction: column;
}
 
.footer4-content {
  gap: var(--dl-layout-space-twounits);
  display: flex;
  align-items: center;
  flex-direction: column;
}
 
.footer4-logo {
  gap: 24px;
  border: 2px dashed rgba(120, 120, 120, 0.4);
  display: flex;
  overflow: hidden;
  align-items: flex-start;
  flex-direction: column;
}
 
.footer4-links {
  gap: var(--dl-layout-space-twounits);
  display: flex;
  align-items: flex-start;
}
 
.footer4-credits {
  gap: var(--dl-layout-space-twounits);
  display: flex;
  align-self: stretch;
  align-items: center;
  flex-direction: column;
}
 
.footer4-row {
  display: flex;
  align-self: stretch;
  align-items: flex-start;
  flex-shrink: 0;
  justify-content: space-between;
}
 
.footer4-container {
  display: flex;
  align-items: flex-start;
}
 
.footer4-footer-links {
  gap: 24px;
  display: flex;
  align-items: flex-start;
}
 
.footer4-text2 {
  fill: var(--dl-color-theme-neutral-dark);
  color: var(--dl-color-theme-neutral-dark);
}
 
.footer4-fragment1 {
  display: contents;
}
 
.footer4-text5 {
  display: inline-block;
}
 
.footer4-fragment2 {
  display: contents;
}
 
.footer4-text6 {
  display: inline-block;
}
 
.footer4-fragment3 {
  display: contents;
}
 
.footer4-text7 {
  display: inline-block;
}
 
.footer4-fragment4 {
  display: contents;
}
 
.footer4-text8 {
  display: inline-block;
}
 
.footer4-fragment5 {
  display: contents;
}
 
.footer4-text9 {
  display: inline-block;
}
 
@media(max-width: 767px) {
  .footer4-row {
    gap: var(--dl-layout-space-oneandhalfunits);
    align-items: center;
    flex-direction: column;
    justify-content: center;
  }
}
 
@media(max-width: 479px) {
  .footer4-max-width {
    gap: var(--dl-layout-space-oneandhalfunits);
  }
  .footer4-links {
    flex-direction: column;
  }
  .footer4-footer-links {
    align-items: center;
    flex-direction: column;
    justify-content: center;
  }
}
</style>
