<template>
  <div class="home-container">
    <ContactModal ref="contactModal" />
    <app-navbar8
      logoSrc="https://images.unsplash.com/photo-1620632523414-054c7bea12ac?crop=entropy&amp;cs=tinysrgb&amp;fit=max&amp;fm=jpg&amp;ixid=M3w5MTMyMXwwfDF8cmFuZG9tfHx8fHx8fHx8MTc0MjI4NjcxMHw&amp;ixlib=rb-4.0.3&amp;q=80&amp;w=1080"
      page1ImageSrc="https://images.unsplash.com/photo-1599081786478-0b4922ea39ff?crop=entropy&amp;cs=tinysrgb&amp;fit=max&amp;fm=jpg&amp;ixid=M3w5MTMyMXwwfDF8cmFuZG9tfHx8fHx8fHx8MTc0MjI4NjY0Mnw&amp;ixlib=rb-4.0.3&amp;q=80&amp;w=1080"
      page2ImageSrc="https://images.unsplash.com/photo-1446071103084-c257b5f70672?crop=entropy&amp;cs=tinysrgb&amp;fit=max&amp;fm=jpg&amp;ixid=M3w5MTMyMXwwfDF8cmFuZG9tfHx8fHx8fHx8MTc0MjI4NjY0Mnw&amp;ixlib=rb-4.0.3&amp;q=80&amp;w=1080"
      page3ImageSrc="https://images.unsplash.com/photo-1563224314-dd2f2dd0d00e?crop=entropy&amp;cs=tinysrgb&amp;fit=max&amp;fm=jpg&amp;ixid=M3w5MTMyMXwwfDF8cmFuZG9tfHx8fHx8fHx8MTc0MjI4NjY0Mnw&amp;ixlib=rb-4.0.3&amp;q=80&amp;w=1080"
      page4ImageSrc="https://images.unsplash.com/photo-1454944338482-a69bb95894af?crop=entropy&amp;cs=tinysrgb&amp;fit=max&amp;fm=jpg&amp;ixid=M3w5MTMyMXwwfDF8cmFuZG9tfHx8fHx8fHx8MTc0MjI4NjY0M3w&amp;ixlib=rb-4.0.3&amp;q=80&amp;w=1080"
      link1Url="#top"
      @contact-click="$refs.contactModal.open()"
    >
      <template v-slot:link1>
        <div class="home-fragment10"><span class="home-text10">#home</span></div>
      </template>
      <template v-slot:link2>
        <div class="home-fragment11">
          <span class="home-text11">#services</span>
        </div>
      </template>
      <template v-slot:link3>
        <div class="home-fragment12">
          <span class="home-text12">#projects</span>
        </div>
      </template>

      <template v-slot:page1>
        <div class="home-fragment14"><span class="home-text14">Home</span></div>
      </template>
      <template v-slot:page2>
        <div class="home-fragment15">
          <span class="home-text15">Services</span>
        </div>
      </template>
      <template v-slot:page3>
        <div class="home-fragment16">
          <span class="home-text16">Projects</span>
        </div>
      </template>
      <template v-slot:page4>
        <div class="home-fragment17">
          <span class="home-text17">Contact</span>
        </div>
      </template>
      <template v-slot:action1>
        <div class="home-fragment18">
          <span class="home-text18">Get Started</span>
        </div>
      </template>
      <template v-slot:action2>
        <div class="home-fragment19" @click="$refs.contactModal.open()">
          <span class="home-text19">Contact Us</span>
        </div>
      </template>
      <template v-slot:page1Description>
        <div class="home-fragment20">
          <span class="home-text20">Learn more about our IT studio</span>
        </div>
      </template>
      <template v-slot:page2Description>
        <div class="home-fragment21">
          <span class="home-text21">
            Explore our data, AI, and machine learning services
          </span>
        </div>
      </template>
      <template v-slot:page3Description>
        <div class="home-fragment22">
          <span class="home-text22">
            Discover our latest projects in data and AI
          </span>
        </div>
      </template>
      <template v-slot:page4Description>
        <div class="home-fragment23" @click="$refs.contactModal.open()">
          <span class="home-text23">
            Get in touch with us for collaborations or inquiries
          </span>
        </div>
      </template>
    </app-navbar8>
    <app-features24
      feature1ImgSrc="https://images.unsplash.com/photo-1518186285589-2f7649de83e0?crop=entropy&amp;cs=tinysrgb&amp;fit=max&amp;fm=jpg&amp;ixid=M3w5MTMyMXwwfDF8cmFuZG9tfHx8fHx8fHx8MTc0MjI4NzQ0M3w&amp;ixlib=rb-4.0.3&amp;q=80&amp;w=1080"
      feature2ImgSrc="https://images.unsplash.com/photo-1518186285589-2f7649de83e0?crop=entropy&amp;cs=tinysrgb&amp;fit=max&amp;fm=jpg&amp;ixid=M3w5MTMyMXwwfDF8cmFuZG9tfHx8fHx8fHx8MTc0MjI4NzQ0M3w&amp;ixlib=rb-4.0.3&amp;q=80&amp;w=1080"
      feature3ImgSrc="https://images.unsplash.com/photo-1518186285589-2f7649de83e0?crop=entropy&amp;cs=tinysrgb&amp;fit=max&amp;fm=jpg&amp;ixid=M3w5MTMyMXwwfDF8cmFuZG9tfHx8fHx8fHx8MTc0MjI4NzQ0M3w&amp;ixlib=rb-4.0.3&amp;q=80&amp;w=1080"
    >
      <template v-slot:feature1Title>
        <div class="home-fragment24">
          <span class="home-text24">Data Streaming Solutions</span>
        </div>
      </template>
      <template v-slot:feature2Title>
        <div class="home-fragment25">
          <span class="home-text25">Embedded AI for IoT</span>
        </div>
      </template>
      <template v-slot:feature3Title>
        <div class="home-fragment26">
          <span class="home-text26">Agentic Intelligence</span>
        </div>
      </template>
      <template v-slot:feature1Description>
        <div class="home-fragment27">
          <span class="home-text27">
            Tailored streaming solutions to meet the needs of banks and financial
            institutions
          </span>
        </div>
      </template>
      <template v-slot:feature2Description>
        <div class="home-fragment28">
          <span class="home-text28">Embedded AI for Internet of Things</span>
        </div>
      </template>
      <template v-slot:feature3Description>
        <div class="home-fragment29">
          <span class="home-text29">
            Agentic Intelligence for Human  Augmentation
          </span>
        </div>
      </template>
    </app-features24>
    <app-cta26>
      <template v-slot:action1>
        <div class="home-fragment30" @click="$refs.contactModal.open()">
          <span class="home-text30">Get in touch</span>
        </div>
      </template>
      <template v-slot:content1>
        <div class="home-fragment31">
          <span class="home-text31">
            Contact us today to discuss how our cutting-edge solutions can take
            your business to the next level.
          </span>
        </div>
      </template>
      <template v-slot:heading1>
        <div class="home-fragment32">
          <span class="home-text32">
            Ready to transform your business with AI and data?
          </span>
        </div>
      </template>
    </app-cta26>
    <app-features25
      id="services"
      feature1ImgSrc="https://images.unsplash.com/photo-1518186285589-2f7649de83e0?crop=entropy&amp;cs=tinysrgb&amp;fit=max&amp;fm=jpg&amp;ixid=M3w5MTMyMXwwfDF8cmFuZG9tfHx8fHx8fHx8MTc0MjI4NzQ0M3w&amp;ixlib=rb-4.0.3&amp;q=80&amp;w=1080"
      feature2ImgSrc="https://images.unsplash.com/photo-1518186285589-2f7649de83e0?crop=entropy&amp;cs=tinysrgb&amp;fit=max&amp;fm=jpg&amp;ixid=M3w5MTMyMXwwfDF8cmFuZG9tfHx8fHx8fHx8MTc0MjI4NzQ0M3w&amp;ixlib=rb-4.0.3&amp;q=80&amp;w=1080"
      feature3ImgSrc="https://images.unsplash.com/photo-1518186285589-2f7649de83e0?crop=entropy&amp;cs=tinysrgb&amp;fit=max&amp;fm=jpg&amp;ixid=M3w5MTMyMXwwfDF8cmFuZG9tfHx8fHx8fHx8MTc0MjI4NzQ0M3w&amp;ixlib=rb-4.0.3&amp;q=80&amp;w=1080"
    >
      <template v-slot:feature1Title>
        <div class="home-fragment33">
          <span class="home-text33">Streaming Solutions for Finance</span>
        </div>
      </template>
      <template v-slot:feature2Title>
        <div class="home-fragment34">
          <span class="home-text34">Embedded AI for Internet of Things</span>
        </div>
      </template>
      <template v-slot:feature3Title>
        <div class="home-fragment35">
          <span class="home-text35">Agentic Intelligence</span>
        </div>
      </template>
      <template v-slot:feature1Description>
        <div class="home-fragment36">
          <span class="home-text36">
            We provide cutting-edge streaming solutions tailored for the banking
            industry to enhance data processing and analysis in real-time.
          </span>
        </div>
      </template>
      <template v-slot:feature2Description>
        <div class="home-fragment37">
          <span class="home-text37">
            Our expertise in embedded AI enables seamless integration with IoT
            devices, empowering them with intelligent capabilities for enhanced
            performance.
          </span>
        </div>
      </template>
      <template v-slot:feature3Description>
        <div class="home-fragment38">
          <span class="home-text38">
            We specialize in developing agentic intelligence solutions that
            augment human skills.
          </span>
        </div>
      </template>
    </app-features25>
    <app-footer4>
      <template v-slot:link1>
        <div class="home-fragment39">
          <span class="home-text39">About Us</span>
        </div>
      </template>
      <template v-slot:link4>
        <div class="home-fragment40" @click="$refs.contactModal.open()">
          <span class="home-text40">Contact Us</span>
        </div>
      </template>
      <template v-slot:termsLink>
        <div class="home-fragment41">
          <span class="home-text41">Terms and Conditions</span>
        </div>
      </template>
      <template v-slot:cookiesLink>
        <div class="home-fragment42">
          <span class="home-text42">Cookies Policy</span>
        </div>
      </template>
      <template v-slot:privacyLink>
        <div class="home-fragment43">
          <span class="home-text43">Privacy Policy</span>
        </div>
      </template>
    </app-footer4>
  </div>
</template>

<script>
import AppNavbar8 from '../components/navbar8.vue'
import AppFeatures24 from '../components/features24.vue'
import AppCta26 from '../components/cta26.vue'
import AppFeatures25 from '../components/features25.vue'
import AppFooter4 from '../components/footer4.vue'
import ContactModal from '../components/ContactModal.vue'

export default {
  name: 'Home',
  props: {},
  components: {
    AppNavbar8,
    AppFeatures24,
    AppCta26,
    AppFeatures25,
    AppFooter4,
    ContactModal,
  },
  metaInfo: {
    title: 'Cargocode',
    meta: [
      {
        property: 'og:title',
        content: 'Cargocode',
      },
    ],
  },
}
</script>

<style scoped>
.home-container {
  width: 100%;
  display: flex;
  min-height: 100vh;
  align-items: center;
  flex-direction: column;
}
 
.home-fragment10 {
  display: contents;
}
 
.home-text10 {
  display: inline-block;
}
 
.home-fragment11 {
  display: contents;
}
 
.home-text11 {
  display: inline-block;
}
 
.home-fragment12 {
  display: contents;
}
 
.home-text12 {
  display: inline-block;
}
 
.home-fragment13 {
  display: contents;
}
 
.home-text13 {
  display: inline-block;
}
 
.home-fragment14 {
  display: contents;
}
 
.home-text14 {
  display: inline-block;
}
 
.home-fragment15 {
  display: contents;
}
 
.home-text15 {
  display: inline-block;
}
 
.home-fragment16 {
  display: contents;
}
 
.home-text16 {
  display: inline-block;
}
 
.home-fragment17 {
  display: contents;
}
 
.home-text17 {
  display: inline-block;
}
 
.home-fragment18 {
  display: contents;
}
 
.home-text18 {
  display: inline-block;
}
 
.home-fragment19 {
  display: contents;
  cursor: pointer;
}
 
.home-text19 {
  display: inline-block;
}
 
.home-fragment20 {
  display: contents;
}
 
.home-text20 {
  display: inline-block;
}
 
.home-fragment21 {
  display: contents;
}
 
.home-text21 {
  display: inline-block;
}
 
.home-fragment22 {
  display: contents;
}
 
.home-text22 {
  display: inline-block;
}
 
.home-fragment23 {
  display: contents;
  cursor: pointer;
}
 
.home-text23 {
  display: inline-block;
}
 
.home-fragment24 {
  display: contents;
}
 
.home-text24 {
  display: inline-block;
  text-align: left;
}
 
.home-fragment25 {
  display: contents;
}
 
.home-text25 {
  display: inline-block;
}
 
.home-fragment26 {
  display: contents;
}
 
.home-text26 {
  display: inline-block;
}
 
.home-fragment27 {
  display: contents;
}
 
.home-text27 {
  display: inline-block;
}
 
.home-fragment28 {
  display: contents;
}
 
.home-text28 {
  display: inline-block;
}
 
.home-fragment29 {
  display: contents;
}
 
.home-text29 {
  display: inline-block;
}
 
.home-fragment30 {
  display: contents;
}
 
.home-text30 {
  display: inline-block;
}
 
.home-fragment31 {
  display: contents;
}
 
.home-text31 {
  display: inline-block;
}
 
.home-fragment32 {
  display: contents;
}
 
.home-text32 {
  display: inline-block;
}
 
.home-fragment33 {
  display: contents;
}
 
.home-text33 {
  display: inline-block;
}
 
.home-fragment34 {
  display: contents;
}
 
.home-text34 {
  display: inline-block;
}
 
.home-fragment35 {
  display: contents;
}
 
.home-text35 {
  display: inline-block;
}
 
.home-fragment36 {
  display: contents;
}
 
.home-text36 {
  display: inline-block;
}
 
.home-fragment37 {
  display: contents;
}
 
.home-text37 {
  display: inline-block;
}
 
.home-fragment38 {
  display: contents;
}
 
.home-text38 {
  display: inline-block;
}
 
.home-fragment39 {
  display: contents;
}
 
.home-text39 {
  display: inline-block;
}
 
.home-fragment40 {
  display: contents;
}
 
.home-text40 {
  display: inline-block;
}
 
.home-fragment41 {
  display: contents;
}
 
.home-text41 {
  display: inline-block;
}
 
.home-fragment42 {
  display: contents;
}
 
.home-text42 {
  display: inline-block;
}
 
.home-fragment43 {
  display: contents;
}
 
.home-text43 {
  display: inline-block;
}
</style>
