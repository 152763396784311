`<template>
  <div v-if="isOpen" class="cookies-modal">
    <div class="modal-overlay" @click="closeModal"></div>
    <div class="modal-container">
      <div class="modal-header">
        <h1 class="text-3xl font-bold">Cookie Policy</h1>
        <button @click="closeModal" class="close-button">&times;</button>
      </div>
      
      <div class="modal-content">
        <div class="space-y-6">
          <section>
            <h2 class="text-2xl font-semibold mb-4">Our Cookie Policy</h2>
            <p>This website does not use cookies. We prioritize your privacy and have designed our website to function without collecting any browser cookies.</p>
          </section>

          <section>
            <h2 class="text-2xl font-semibold mb-4">What Are Cookies?</h2>
            <p>Cookies are small text files that websites can place on your device when you visit. They are often used for remembering your preferences, analyzing site traffic, or enabling certain website functionality.</p>
          </section>

          <section>
            <h2 class="text-2xl font-semibold mb-4">Our Approach</h2>
            <p>At Cargocode LTD, we believe in transparency and minimizing data collection. We have specifically designed our website to operate without the need for cookies, ensuring your browsing experience remains private.</p>
          </section>

          <section>
            <h2 class="text-2xl font-semibold mb-4">Contact Us</h2>
            <p>If you have any questions about our cookie policy, please contact us at:</p>
            <p class="mt-2">Cargocode LTD<br>
            124 City Road<br>
            London, EC1V 2NX<br>
            United Kingdom<br>
            Email: privacy@cargocode.dev</p>
          </section>

          <section class="mb-8">
            <p class="mt-4 text-sm">Last Updated: March 18, 2025</p>
          </section>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
export default {
  name: 'CookiesModal',
  props: {
    isOpen: {
      type: Boolean,
      required: true
    }
  },
  methods: {
    closeModal() {
      this.$emit('close')
    }
  }
}
</script>

<style scoped>
.cookies-modal {
  position: fixed;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  display: flex;
  align-items: center;
  justify-content: center;
  z-index: 1000;
}

.modal-overlay {
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  background-color: rgba(0, 0, 0, 0.5);
}

.modal-container {
  position: relative;
  width: 90%;
  max-width: 800px;
  max-height: 90vh;
  background-color: white;
  border-radius: 8px;
  box-shadow: 0 2px 10px rgba(0, 0, 0, 0.1);
  z-index: 1001;
  overflow: hidden;
  display: flex;
  flex-direction: column;
}

.modal-header {
  padding: 1.5rem;
  border-bottom: 1px solid #e5e7eb;
  display: flex;
  justify-content: space-between;
  align-items: center;
  background-color: white;
  position: sticky;
  top: 0;
  z-index: 1;
}

.close-button {
  font-size: 1.5rem;
  line-height: 1;
  padding: 0.5rem;
  background: none;
  border: none;
  cursor: pointer;
  color: #6b7280;
}

.close-button:hover {
  color: #1f2937;
}

.modal-content {
  padding: 1.5rem;
  overflow-y: auto;
  color: var(--dl-color-gray-black);
}

section {
  margin-bottom: 2rem;
}

h2 {
  color: var(--dl-color-gray-black);
}

@media (max-width: 640px) {
  .modal-container {
    width: 95%;
    max-height: 95vh;
  }
  
  .modal-header {
    padding: 1rem;
  }
  
  .modal-content {
    padding: 1rem;
  }
}
</style>`
