`<template>
  <div v-if="isOpen" class="privacy-modal">
    <div class="modal-overlay" @click="closeModal"></div>
    <div class="modal-container">
      <div class="modal-header">
        <h1 class="text-3xl font-bold">Privacy Policy</h1>
        <button @click="closeModal" class="close-button">&times;</button>
      </div>
      
      <div class="modal-content">
        <div class="space-y-6">
          <section>
            <h2 class="text-2xl font-semibold mb-4">1. Introduction</h2>
            <p>At Cargocode, we take your privacy seriously. This Privacy Policy explains how we collect, use, disclose, and safeguard your information when you visit our website or use our services.</p>
          </section>

          <section>
            <h2 class="text-2xl font-semibold mb-4">2. Information We Collect</h2>
            <p>We collect information that you voluntarily provide to us when you:</p>
            <ul class="list-disc ml-6 mt-2">
              <li>Contact us through our website</li>
              <li>Subscribe to our newsletter</li>
              <li>Request a consultation</li>
            </ul>
            <p class="mt-2">This information may include:</p>
            <ul class="list-disc ml-6 mt-2">
              <li>Name and email address</li>
              <li>Company name and job title</li>
              <li>Message content</li>
            </ul>
          </section>

          <section>
            <h2 class="text-2xl font-semibold mb-4">3. How We Use Your Information</h2>
            <p>We use the information we collect to:</p>
            <ul class="list-disc ml-6 mt-2">
              <li>Respond to your inquiries and provide customer service</li>
              <li>Send you marketing and promotional communications (with your consent)</li>
              <li>Improve our website and services</li>
              <li>Comply with legal obligations</li>
            </ul>
          </section>

          <section>
            <h2 class="text-2xl font-semibold mb-4">4. Information Sharing</h2>
            <p>We do not sell, trade, or otherwise transfer your personal information to third parties without your consent, except as required by law or to trusted third parties who assist us in operating our website and conducting our business.</p>
          </section>

          <section>
            <h2 class="text-2xl font-semibold mb-4">5. Data Security</h2>
            <p>We implement appropriate technical and organizational measures to protect your personal information against unauthorized access, alteration, disclosure, or destruction.</p>
          </section>

          <section>
            <h2 class="text-2xl font-semibold mb-4">6. Your Rights</h2>
            <p>You have the right to:</p>
            <ul class="list-disc ml-6 mt-2">
              <li>Access your personal information</li>
              <li>Correct inaccurate information</li>
              <li>Request deletion of your information</li>
              <li>Object to our processing of your information</li>
              <li>Withdraw consent at any time</li>
            </ul>
          </section>

          <section>
            <h2 class="text-2xl font-semibold mb-4">7. Contact Us</h2>
            <p>If you have questions about this Privacy Policy or our privacy practices, please contact us at:</p>
            <p class="mt-2">Email: privacy@cargocode.dev</p>
          </section>

          <section>
            <h2 class="text-2xl font-semibold mb-4">8. Updates to This Policy</h2>
            <p>We may update this Privacy Policy from time to time. The updated version will be indicated by an updated "Last Updated" date and the updated version will be effective as soon as it is accessible.</p>
            <p class="mt-4">Last Updated: March 18, 2025</p>
          </section>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
export default {
  name: 'PrivacyModal',
  props: {
    isOpen: {
      type: Boolean,
      required: true
    }
  },
  methods: {
    closeModal() {
      this.$emit('close')
    }
  }
}
</script>

<style scoped>
.privacy-modal {
  position: fixed;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  display: flex;
  align-items: center;
  justify-content: center;
  z-index: 1000;
}

.modal-overlay {
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  background-color: rgba(0, 0, 0, 0.5);
}

.modal-container {
  position: relative;
  width: 90%;
  max-width: 800px;
  max-height: 90vh;
  background-color: white;
  border-radius: 8px;
  box-shadow: 0 2px 10px rgba(0, 0, 0, 0.1);
  z-index: 1001;
  overflow: hidden;
  display: flex;
  flex-direction: column;
}

.modal-header {
  padding: 1.5rem;
  border-bottom: 1px solid #e5e7eb;
  display: flex;
  justify-content: space-between;
  align-items: center;
  background-color: white;
  position: sticky;
  top: 0;
  z-index: 1;
}

.close-button {
  font-size: 1.5rem;
  line-height: 1;
  padding: 0.5rem;
  background: none;
  border: none;
  cursor: pointer;
  color: #6b7280;
}

.close-button:hover {
  color: #1f2937;
}

.modal-content {
  padding: 1.5rem;
  overflow-y: auto;
  color: var(--dl-color-gray-black);
}

section {
  margin-bottom: 2rem;
}

h2 {
  color: var(--dl-color-gray-black);
}

ul {
  margin-left: 1.5rem;
}

li {
  margin-bottom: 0.5rem;
}

@media (max-width: 640px) {
  .modal-container {
    width: 95%;
    max-height: 95vh;
  }
  
  .modal-header {
    padding: 1rem;
  }
  
  .modal-content {
    padding: 1rem;
  }
}
</style>`
