`<template>
  <div v-if="isOpen" class="terms-modal">
    <div class="modal-overlay" @click="closeModal"></div>
    <div class="modal-container">
      <div class="modal-header">
        <h1 class="text-3xl font-bold">Terms and Conditions</h1>
        <button @click="closeModal" class="close-button">&times;</button>
      </div>
      
      <div class="modal-content">
        <div class="space-y-6">
          <section>
            <h2 class="text-2xl font-semibold mb-4">1. Introduction</h2>
            <p>These terms and conditions govern your use of the website operated by Cargocode LTD (Company Number: 07958264), registered in England with its registered office at 124 City Road, London, EC1V 2NX ("we", "our", or "us").</p>
            <p class="mt-2">By accessing and using our website, you accept and agree to be bound by these terms and conditions. If you do not agree to these terms, you must not use our website.</p>
          </section>

          <section>
            <h2 class="text-2xl font-semibold mb-4">2. Use of Our Website</h2>
            <p>You agree to use our website only for lawful purposes and in a manner that does not infringe the rights of any third party. You must not:</p>
            <ul class="list-disc ml-6 mt-2">
              <li>Use our website in any way that breaches any applicable laws or regulations</li>
              <li>Transmit any material that is defamatory, offensive, or otherwise objectionable</li>
              <li>Attempt to gain unauthorized access to our website or server</li>
              <li>Interfere with any other user's enjoyment of our website</li>
            </ul>
          </section>

          <section>
            <h2 class="text-2xl font-semibold mb-4">3. Intellectual Property Rights</h2>
            <p>All intellectual property rights in our website and its content (including text, graphics, logos, images, and software) are owned by or licensed to Cargocode LTD. These works are protected by copyright laws and all such rights are reserved.</p>
            <p class="mt-2">You may print or download content from our website for your personal use, but you must not:</p>
            <ul class="list-disc ml-6 mt-2">
              <li>Modify any materials you have printed or downloaded</li>
              <li>Use any illustrations, photographs, or graphics separately from accompanying text</li>
              <li>Use any content for commercial purposes without obtaining a license from us</li>
            </ul>
          </section>

          <section>
            <h2 class="text-2xl font-semibold mb-4">4. Service Description</h2>
            <p>We provide software development and consulting services. While we strive to ensure that our website is available 24/7, we do not guarantee that our website will be secure or free from bugs or viruses.</p>
            <p class="mt-2">You are responsible for configuring your technology to access our website. You should use your own virus protection software.</p>
          </section>

          <section>
            <h2 class="text-2xl font-semibold mb-4">5. Limitation of Liability</h2>
            <p>Nothing in these terms excludes or limits our liability for death or personal injury arising from our negligence, fraud, or any other liability that cannot be excluded or limited by English law.</p>
            <p class="mt-2">To the extent permitted by law, we exclude:</p>
            <ul class="list-disc ml-6 mt-2">
              <li>All conditions, warranties, and representations implied by law</li>
              <li>Any liability for direct, indirect, or consequential loss or damage incurred by any user in connection with our website</li>
            </ul>
          </section>

          <section>
            <h2 class="text-2xl font-semibold mb-4">6. Links to Third-Party Websites</h2>
            <p>Our website may contain links to third-party websites. These links are provided for your convenience only. We have no control over the content of these websites and accept no responsibility for them or any loss or damage that may arise from your use of them.</p>
          </section>

          <section>
            <h2 class="text-2xl font-semibold mb-4">7. Changes to These Terms</h2>
            <p>We may revise these terms at any time by amending this page. Please check this page regularly to ensure you understand the terms that apply at that time.</p>
          </section>

          <section>
            <h2 class="text-2xl font-semibold mb-4">8. Governing Law</h2>
            <p>These terms are governed by English law. You and we both agree to submit to the exclusive jurisdiction of the courts of England and Wales.</p>
          </section>

          <section>
            <h2 class="text-2xl font-semibold mb-4">9. Contact Us</h2>
            <p>If you have any questions about these terms and conditions, please contact us at:</p>
            <p class="mt-2">Cargocode LTD<br>
            124 City Road<br>
            London, EC1V 2NX<br>
            United Kingdom<br>
            Email: legal@cargocode.dev</p>
          </section>

          <section class="mb-8">
            <p class="mt-4 text-sm">Last Updated: March 18, 2025</p>
          </section>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
export default {
  name: 'TermsModal',
  props: {
    isOpen: {
      type: Boolean,
      required: true
    }
  },
  methods: {
    closeModal() {
      this.$emit('close')
    }
  }
}
</script>

<style scoped>
.terms-modal {
  position: fixed;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  display: flex;
  align-items: center;
  justify-content: center;
  z-index: 1000;
}

.modal-overlay {
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  background-color: rgba(0, 0, 0, 0.5);
}

.modal-container {
  position: relative;
  width: 90%;
  max-width: 800px;
  max-height: 90vh;
  background-color: white;
  border-radius: 8px;
  box-shadow: 0 2px 10px rgba(0, 0, 0, 0.1);
  z-index: 1001;
  overflow: hidden;
  display: flex;
  flex-direction: column;
}

.modal-header {
  padding: 1.5rem;
  border-bottom: 1px solid #e5e7eb;
  display: flex;
  justify-content: space-between;
  align-items: center;
  background-color: white;
  position: sticky;
  top: 0;
  z-index: 1;
}

.close-button {
  font-size: 1.5rem;
  line-height: 1;
  padding: 0.5rem;
  background: none;
  border: none;
  cursor: pointer;
  color: #6b7280;
}

.close-button:hover {
  color: #1f2937;
}

.modal-content {
  padding: 1.5rem;
  overflow-y: auto;
  color: var(--dl-color-gray-black);
}

section {
  margin-bottom: 2rem;
}

h2 {
  color: var(--dl-color-gray-black);
}

ul {
  margin-left: 1.5rem;
}

li {
  margin-bottom: 0.5rem;
}

@media (max-width: 640px) {
  .modal-container {
    width: 95%;
    max-height: 95vh;
  }
  
  .modal-header {
    padding: 1rem;
  }
  
  .modal-content {
    padding: 1rem;
  }
}
</style>`
