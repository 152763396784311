<template>
  <header class="navbar8-container1">
    <header data-thq="thq-navbar" class="navbar8-navbar-interactive">
      <div data-thq="thq-navbar-nav" class="navbar8-desktop-menu">
        <nav class="navbar8-links1">
          <a @click="scrollToTop" href="#" class="navbar8-link11 thq-body-small thq-link">
            <slot name="link1">
              <!--Default content for link1-->
              <div><span>#home</span></div>
            </slot>
          </a>
          <a href="#services" class="thq-body-small thq-link">
            <slot name="link2">
              <!--Default content for link2-->
              <div><span>#services</span></div>
            </slot>
          </a>

        </nav>
        <div class="navbar8-buttons1">
          <button class="navbar8-action21 thq-button-animated thq-button-outline" @click="$emit('contact-click')">
            <span class="thq-body-small">
              <slot name="action2">
                <!--Default content for action2-->
                <div><span>Contact Us</span></div>
              </slot>
            </span>
          </button>
        </div>
      </div>
      <div data-thq="thq-burger-menu" class="navbar8-burger-menu" @click="isMobileMenuOpen = true" style="cursor: pointer;">
        <svg viewBox="0 0 1024 1024" class="navbar8-icon14">
          <path
            d="M128 554.667h768c23.552 0 42.667-19.115 42.667-42.667s-19.115-42.667-42.667-42.667h-768c-23.552 0-42.667 19.115-42.667 42.667s19.115 42.667 42.667 42.667zM128 298.667h768c23.552 0 42.667-19.115 42.667-42.667s-19.115-42.667-42.667-42.667h-768c-23.552 0-42.667 19.115-42.667 42.667s19.115 42.667 42.667 42.667zM128 810.667h768c23.552 0 42.667-19.115 42.667-42.667s-19.115-42.667-42.667-42.667h-768c-23.552 0-42.667 19.115-42.667 42.667s19.115 42.667 42.667 42.667z"
          ></path>
        </svg>
      </div>
      <div data-thq="thq-mobile-menu" class="navbar8-mobile-menu" v-if="isMobileMenuOpen">
        <div class="navbar8-nav">
          <div class="navbar8-top">
            <div data-thq="thq-close-menu" class="navbar8-close-menu" @click="isMobileMenuOpen = false" style="cursor: pointer;">
              <svg viewBox="0 0 1024 1024" class="navbar8-icon16">
                <path
                  d="M810 274l-238 238 238 238-60 60-238-238-238 238-60-60 238-238-238-238 60-60 238 238 238-238z"
                ></path>
              </svg>
            </div>
          </div>
          <nav class="navbar8-links2">
            <a :href="link1Url" class="navbar8-link12 thq-body-small thq-link">
              <slot name="link1">
                <!--Default content for link1-->
                <div class="navbar8-fragment19">
                  <span class="navbar8-text22">#home</span>
                </div>
              </slot>
            </a>
            <a href="#services" class="thq-body-small thq-link" @click="isMobileMenuOpen = false">
              <slot name="link2">
                <!--Default content for link2-->
                <div class="navbar8-fragment15">
                  <span class="navbar8-text18">#services</span>
                </div>
              </slot>
            </a>

          </nav>

        </div>

      </div>
      <div v-if="link5DropdownVisible" class="navbar8-container7 thq-box-shadow">
        <div class="navbar8-link5-menu-list">
          <a :href="linkUrlPage1">
            <div class="navbar8-menu-item5">
              <img
                :alt="page1ImageAlt"
                :src="page1ImageSrc"
                class="navbar8-page1-image2 thq-img-ratio-1-1"
              />
              <div class="navbar8-content5">
                <span class="navbar8-page12 thq-body-large">
                  <slot name="page1">
                    <!--Default content for page1-->
                    <div class="navbar8-fragment22">
                      <span class="navbar8-text25">Home</span>
                    </div>
                  </slot>
                </span>
                <span class="thq-body-small">
                  <slot name="page1Description">
                    <!--Default content for page1Description-->
                    <div class="navbar8-fragment20">
                      <span class="navbar8-text23">
                        Learn more about our IT studio
                      </span>
                    </div>
                  </slot>
                </span>
              </div>
            </div>
          </a>
          <a :href="linkUrlPage2">
            <div class="navbar8-menu-item6">
              <img
                :alt="page2ImageAlt"
                :src="page2ImageSrc"
                class="navbar8-page2-image2 thq-img-ratio-1-1"
              />
              <div class="navbar8-content6">
                <span class="navbar8-page22 thq-body-large">
                  <slot name="page2">
                    <!--Default content for page2-->
                    <div class="navbar8-fragment12">
                      <span class="navbar8-text15">Services</span>
                    </div>
                  </slot>
                </span>
                <span class="thq-body-small">
                  <slot name="page2Description">
                    <!--Default content for page2Description-->
                    <div class="navbar8-fragment23">
                      <span class="navbar8-text26">
                        Explore our data, AI, and machine learning services
                      </span>
                    </div>
                  </slot>
                </span>
              </div>
            </div>
          </a>
          <a :href="linkUrlPage3">
            <div class="navbar8-menu-item7">
              <img
                :alt="page3ImageAlt"
                :src="page3ImageSrc"
                class="navbar8-page3-image2 thq-img-ratio-1-1"
              />
              <div class="navbar8-content7">
                <span class="navbar8-page32 thq-body-large">
                  <slot name="page3">
                    <!--Default content for page3-->
                    <div class="navbar8-fragment11">
                      <span class="navbar8-text14">Projects</span>
                    </div>
                  </slot>
                </span>
                <span class="thq-body-small">
                  <slot name="page3Description">
                    <!--Default content for page3Description-->
                    <div class="navbar8-fragment13">
                      <span class="navbar8-text16">
                        Discover our latest projects in data and AI
                      </span>
                    </div>
                  </slot>
                </span>
              </div>
            </div>
          </a>
          <a :href="linkUrlPage4">
            <div class="navbar8-menu-item8">
              <img
                :alt="page4ImageAlt"
                :src="page4ImageSrc"
                class="navbar8-page4-image2 thq-img-ratio-1-1"
              />
              <div class="navbar8-content8">
                <span class="navbar8-page42 thq-body-large">
                  <slot name="page4">
                    <!--Default content for page4-->
                    <div class="navbar8-fragment14">
                      <span class="navbar8-text17">Contact</span>
                    </div>
                  </slot>
                </span>
                <span class="thq-body-small">
                  <slot name="page4Description">
                    <!--Default content for page4Description-->
                    <div class="navbar8-fragment21">
                      <span class="navbar8-text24">
                        Get in touch with us for collaborations or inquiries
                      </span>
                    </div>
                  </slot>
                </span>
              </div>
            </div>
          </a>
        </div>
      </div>
    </header>
    <div
      @click="link5DropdownVisible = false"
      v-if="link5DropdownVisible"
      class="navbar8-container8"
    ></div>
  </header>
</template>

<script>
import './navbar8.css'
export default {
  name: 'Navbar8',
  props: {
    page1ImageAlt: {
      type: String,
      default: 'Image representing our IT studio',
    },
    page2ImageAlt: {
      type: String,
      default: 'Image representing our services',
    },
    link2Url: {
      type: String,
      default: 'https://www.teleporthq.io',
    },
    page4ImageAlt: {
      type: String,
      default: 'Image representing contact us',
    },
    page2ImageSrc: {
      type: String,
      default:
        'https://images.unsplash.com/photo-1626105962135-b29dd5474d1f?crop=entropy&cs=tinysrgb&fit=max&fm=jpg&ixid=M3w5MTMyMXwwfDF8cmFuZG9tfHx8fHx8fHx8MTc0MjI4NTY0NXw&ixlib=rb-4.0.3&q=80&w=1080',
    },
    link1Url: {
      type: String,
      default: 'https://www.teleporthq.io',
    },
    page4ImageSrc: {
      type: String,
      default:
        'https://images.unsplash.com/photo-1655891709780-924828bc7cf4?crop=entropy&cs=tinysrgb&fit=max&fm=jpg&ixid=M3w5MTMyMXwwfDF8cmFuZG9tfHx8fHx8fHx8MTc0MjI4NTY0NXw&ixlib=rb-4.0.3&q=80&w=1080',
    },
    page1ImageSrc: {
      type: String,
      default:
        'https://images.unsplash.com/photo-1513258496099-48168024aec0?crop=entropy&cs=tinysrgb&fit=max&fm=jpg&ixid=M3w5MTMyMXwwfDF8cmFuZG9tfHx8fHx8fHx8MTc0MjI4NTY0NHw&ixlib=rb-4.0.3&q=80&w=1080',
    },
    page3ImageSrc: {
      type: String,
      default:
        'https://images.unsplash.com/photo-1598550463415-d397fdddc3e0?crop=entropy&cs=tinysrgb&fit=max&fm=jpg&ixid=M3w5MTMyMXwwfDF8cmFuZG9tfHx8fHx8fHx8MTc0MjI4NTY0NXw&ixlib=rb-4.0.3&q=80&w=1080',
    },
    page3ImageAlt: {
      type: String,
      default: 'Image representing our projects',
    },
    logoAlt: {
      type: String,
      default: 'Cargocode',
    },
    linkUrlPage4: {
      type: String,
      default: 'https://www.teleporthq.io',
    },
    link3Url: {
      type: String,
      default: 'https://www.teleporthq.io',
    },
    linkUrlPage3: {
      type: String,
      default: 'https://www.teleporthq.io',
    },
    logoSrc: {
      type: String,
      default:
        'https://aheioqhobo.cloudimg.io/v7/_playground-bucket-v2.teleporthq.io_/84ec08e8-34e9-42c7-9445-d2806d156403/fac575ac-7a41-484f-b7ac-875042de11f8?org_if_sml=1&force_format=original',
    },
    linkUrlPage1: {
      type: String,
      default: 'https://www.teleporthq.io',
    },
    linkUrlPage2: {
      type: String,
      default: 'https://www.teleporthq.io',
    },
  },
  data() {
    return {
      link5AccordionOpen: false,
      link5DropdownVisible: false,
      isMobileMenuOpen: false
    }
  },
  methods: {
    scrollToTop(e) {
      e.preventDefault()
      window.scrollTo({
        top: 0,
        behavior: 'smooth'
      })
    }
  },
}
</script>

<style scoped>
.navbar8-container1 {
  top: 0;
  width: 100%;
  display: flex;
  z-index: 1000;
  position: sticky;
  justify-content: center;
  background-color: var(--dl-color-theme-neutral-light);
}
 
.navbar8-navbar-interactive {
  width: 100%;
  display: flex;
  z-index: 100;
  max-width: var(--dl-layout-size-maxwidth);
  align-items: center;
  padding-top: var(--dl-layout-space-oneandhalfunits);
  padding-left: var(--dl-layout-space-threeunits);
  padding-right: var(--dl-layout-space-threeunits);
  padding-bottom: var(--dl-layout-space-oneandhalfunits);
  justify-content: space-between;
}
 
.navbar8-desktop-menu {
  flex: 1;
  display: flex;
  justify-content: space-between;
}
 
.navbar8-links1 {
  gap: var(--dl-layout-space-twounits);
  flex: 1;
  display: flex;
  align-items: center;
  margin-left: var(--dl-layout-space-twounits);
  flex-direction: row;
  justify-content: flex-start;
}
 
.navbar8-link11 {
  text-decoration: none;
}
 
.navbar8-link4-dropdown-trigger {
  gap: 4px;
  cursor: pointer;
  display: flex;
  align-self: stretch;
  align-items: center;
  flex-direction: row;
  justify-content: flex-end;
}
 
.navbar8-icon-container1 {
  display: flex;
  align-items: center;
  flex-direction: row;
  justify-content: flex-end;
}
 
.navbar8-container2 {
  display: flex;
  align-items: center;
  animation-name: rotateInDownLeft;
  flex-direction: row;
  animation-delay: 0s;
  justify-content: flex-end;
  animation-duration: 500ms;
  animation-direction: normal;
  animation-iteration-count: 1;
  animation-timing-function: ease;
}
 
.navbar8-icon10 {
  width: 24px;
  height: 24px;
}
 
.navbar8-container3 {
  display: flex;
  align-items: center;
  animation-name: rotateInDownRight;
  flex-direction: row;
  animation-delay: 0s;
  justify-content: flex-end;
  animation-duration: 500ms;
  animation-direction: normal;
  animation-iteration-count: 1;
  animation-timing-function: ease;
}
 
.navbar8-icon12 {
  width: 24px;
  height: 24px;
}
 
.navbar8-buttons1 {
  gap: var(--dl-layout-space-twounits);
  display: flex;
  align-items: center;
  flex-direction: row;
}
 
.navbar8-action21 {
  display: flex;
  flex-direction: row;
}
 
.navbar8-burger-menu {
  display: none;
}
 
.navbar8-icon14 {
  width: var(--dl-layout-size-xsmall);
  height: var(--dl-layout-size-xsmall);
}
 
.navbar8-mobile-menu {
  top: 0px;
  left: 0px;
  width: 100%;
  height: 100vh;
  display: flex;
  padding: var(--dl-layout-space-twounits);
  z-index: 100;
  position: fixed;
  flex-direction: column;
  justify-content: flex-start;
  background-color: var(--dl-color-theme-neutral-light);
}
 
.navbar8-nav {
  display: flex;
  align-items: flex-start;
  flex-direction: column;
}
 
.navbar8-top {
  width: 100%;
  display: flex;
  align-items: center;
  margin-bottom: var(--dl-layout-space-threeunits);
  justify-content: space-between;
}
 
.navbar8-logo {
  height: 3rem;
}
 
.navbar8-close-menu {
  display: flex;
  align-items: center;
  justify-content: center;
}
 
.navbar8-icon16 {
  width: var(--dl-layout-size-xsmall);
  height: var(--dl-layout-size-xsmall);
}
 
.navbar8-links2 {
  gap: var(--dl-layout-space-unit);
  flex: 0 0 auto;
  width: 100%;
  display: flex;
  align-self: flex-start;
  flex-direction: column;
}
 
.navbar8-link12 {
  text-decoration: none;
}
 
.navbar8-link3 {
  text-decoration: none;
}
 
.navbar8-link4-accordion {
  display: flex;
  align-items: flex-start;
  flex-direction: column;
}
 
.navbar8-trigger {
  width: 100%;
  cursor: pointer;
  display: flex;
  align-items: flex-start;
  flex-direction: row;
  justify-content: space-between;
}
 
.navbar8-icon-container2 {
  display: flex;
  align-items: center;
  flex-direction: row;
  justify-content: flex-end;
}
 
.navbar8-container4 {
  display: flex;
  align-items: center;
  flex-direction: row;
  justify-content: flex-end;
}
 
.navbar8-icon18 {
  width: 24px;
  height: 24px;
}
 
.navbar8-container5 {
  display: flex;
  align-items: center;
  flex-direction: row;
  justify-content: flex-end;
}
 
.navbar8-icon20 {
  width: 24px;
  height: 24px;
}
 
.navbar8-container6 {
  width: 100%;
  display: grid;
  grid-gap: var(--dl-layout-space-halfunit);
  grid-template-columns: 1fr;
}
 
.navbar8-menu-item1 {
  gap: var(--dl-layout-space-unit);
  width: 100%;
  cursor: pointer;
  display: flex;
  padding: var(--dl-layout-space-oneandhalfunits);
  align-self: stretch;
  align-items: center;
  flex-shrink: 0;
}
 
.navbar8-page1-image1 {
  width: 50px;
  height: 50px;
}
 
.navbar8-content1 {
  width: 340px;
  display: flex;
  flex-grow: 1;
  align-items: flex-start;
  flex-shrink: 0;
  flex-direction: column;
}
 
.navbar8-page11 {
  font-style: normal;
  font-weight: 600;
}
 
.navbar8-menu-item2 {
  gap: var(--dl-layout-space-unit);
  width: 100%;
  cursor: pointer;
  display: flex;
  padding: var(--dl-layout-space-oneandhalfunits);
  align-self: stretch;
  align-items: center;
  flex-shrink: 0;
}
 
.navbar8-page2-image1 {
  width: 50px;
  height: 50px;
}
 
.navbar8-content2 {
  width: 340px;
  display: flex;
  flex-grow: 1;
  align-items: flex-start;
  flex-shrink: 0;
  flex-direction: column;
}
 
.navbar8-page21 {
  font-style: normal;
  font-weight: 600;
}
 
.navbar8-menu-item3 {
  gap: var(--dl-layout-space-unit);
  width: 100%;
  cursor: pointer;
  display: flex;
  padding: var(--dl-layout-space-oneandhalfunits);
  align-self: stretch;
  align-items: center;
  flex-shrink: 0;
}
 
.navbar8-page3-image1 {
  width: 50px;
  height: 50px;
}
 
.navbar8-content3 {
  width: 340px;
  display: flex;
  flex-grow: 1;
  align-items: flex-start;
  flex-shrink: 0;
  flex-direction: column;
}
 
.navbar8-page31 {
  font-style: normal;
  font-weight: 600;
}
 
.navbar8-menu-item4 {
  gap: var(--dl-layout-space-unit);
  width: 100%;
  cursor: pointer;
  display: flex;
  padding: var(--dl-layout-space-oneandhalfunits);
  align-self: stretch;
  align-items: center;
  flex-shrink: 0;
}
 
.navbar8-page4-image1 {
  width: 50px;
  height: 50px;
}
 
.navbar8-content4 {
  width: 340px;
  display: flex;
  flex-grow: 1;
  align-items: flex-start;
  flex-shrink: 0;
  flex-direction: column;
}
 
.navbar8-page41 {
  font-style: normal;
  font-weight: 600;
}
 
.navbar8-buttons2 {
  gap: var(--dl-layout-space-twounits);
  display: flex;
  margin-top: var(--dl-layout-space-twounits);
  align-items: center;
  flex-direction: row;
}
 
.navbar8-icon-group {
  gap: var(--dl-layout-space-twounits);
  display: flex;
}
 
.navbar8-container7 {
  top: 100%;
  left: 0px;
  width: 100%;
  display: flex;
  position: absolute;
  align-items: center;
  animation-name: fadeInDownBig;
  animation-delay: 0s;
  justify-content: center;
  background-color: var(--dl-color-theme-neutral-light);
  animation-duration: 300ms;
  animation-direction: normal;
  animation-iteration-count: 1;
  animation-timing-function: ease;
}
 
.navbar8-link5-menu-list {
  width: 100%;
  display: grid;
  padding: var(--dl-layout-space-oneandhalfunits);
  grid-gap: var(--dl-layout-space-halfunit);
  max-width: var(--dl-layout-size-maxwidth);
  grid-template-columns: 1fr 1fr 1fr 1fr;
}
 
.navbar8-menu-item5 {
  gap: var(--dl-layout-space-unit);
  width: 100%;
  cursor: pointer;
  display: flex;
  padding: var(--dl-layout-space-oneandhalfunits);
  align-self: stretch;
  align-items: center;
  text-decoration: none;
}
 
.navbar8-page1-image2 {
  width: 30px;
  height: 30px;
}
 
.navbar8-content5 {
  display: flex;
  flex-grow: 1;
  align-items: flex-start;
  flex-direction: column;
}
 
.navbar8-page12 {
  font-style: normal;
  font-weight: 600;
}
 
.navbar8-menu-item6 {
  gap: var(--dl-layout-space-unit);
  width: 100%;
  cursor: pointer;
  display: flex;
  padding: var(--dl-layout-space-oneandhalfunits);
  align-self: stretch;
  align-items: center;
  text-decoration: none;
}
 
.navbar8-page2-image2 {
  width: 30px;
  height: 30px;
}
 
.navbar8-content6 {
  display: flex;
  flex-grow: 1;
  align-items: flex-start;
  flex-direction: column;
}
 
.navbar8-page22 {
  font-style: normal;
  font-weight: 600;
}
 
.navbar8-menu-item7 {
  gap: var(--dl-layout-space-unit);
  width: 100%;
  cursor: pointer;
  display: flex;
  padding: var(--dl-layout-space-oneandhalfunits);
  align-self: stretch;
  align-items: center;
  text-decoration: none;
}
 
.navbar8-page3-image2 {
  width: 30px;
  height: 30px;
}
 
.navbar8-content7 {
  display: flex;
  flex-grow: 1;
  align-items: flex-start;
  flex-direction: column;
}
 
.navbar8-page32 {
  font-style: normal;
  font-weight: 600;
}
 
.navbar8-menu-item8 {
  gap: var(--dl-layout-space-unit);
  width: 100%;
  cursor: pointer;
  display: flex;
  padding: var(--dl-layout-space-oneandhalfunits);
  align-self: stretch;
  align-items: center;
  text-decoration: none;
}
 
.navbar8-page4-image2 {
  width: 30px;
  height: 30px;
}
 
.navbar8-content8 {
  display: flex;
  flex-grow: 1;
  align-items: flex-start;
  flex-direction: column;
}
 
.navbar8-page42 {
  font-style: normal;
  font-weight: 600;
}
 
.navbar8-container8 {
  top: 0px;
  width: 100%;
  height: 100%;
  display: flex;
  position: fixed;
  align-items: flex-start;
  flex-direction: column;
}
 
.navbar8-fragment10 {
  display: contents;
}
 
.navbar8-text13 {
  display: inline-block;
}
 
.navbar8-fragment11 {
  display: contents;
}
 
.navbar8-text14 {
  display: inline-block;
}
 
.navbar8-fragment12 {
  display: contents;
}
 
.navbar8-text15 {
  display: inline-block;
}
 
.navbar8-fragment13 {
  display: contents;
}
 
.navbar8-text16 {
  display: inline-block;
}
 
.navbar8-fragment14 {
  display: contents;
}
 
.navbar8-text17 {
  display: inline-block;
}
 
.navbar8-fragment15 {
  display: contents;
}
 
.navbar8-text18 {
  display: inline-block;
}
 
.navbar8-fragment16 {
  display: contents;
}
 
.navbar8-text19 {
  display: inline-block;
}
 
.navbar8-fragment17 {
  display: contents;
}
 
.navbar8-text20 {
  display: inline-block;
}
 
.navbar8-fragment18 {
  display: contents;
}
 
.navbar8-text21 {
  display: inline-block;
}
 
.navbar8-fragment19 {
  display: contents;
}
 
.navbar8-text22 {
  display: inline-block;
}
 
.navbar8-fragment20 {
  display: contents;
}
 
.navbar8-text23 {
  display: inline-block;
}
 
.navbar8-fragment21 {
  display: contents;
}
 
.navbar8-text24 {
  display: inline-block;
}
 
.navbar8-fragment22 {
  display: contents;
}
 
.navbar8-text25 {
  display: inline-block;
}
 
.navbar8-fragment23 {
  display: contents;
}
 
.navbar8-text26 {
  display: inline-block;
}
 
@media(max-width: 767px) {
  .navbar8-navbar-interactive {
    padding-left: var(--dl-layout-space-twounits);
    padding-right: var(--dl-layout-space-twounits);
  }
  .navbar8-desktop-menu {
    display: none;
  }
  .navbar8-burger-menu {
    display: flex;
    align-items: center;
    justify-content: center;
  }
  .navbar8-mobile-menu {
    padding-top: var(--dl-layout-space-oneandhalfunits);
    padding-bottom: var(--dl-layout-space-oneandhalfunits);
  }
  .navbar8-container6 {
    grid-gap: 0;
  }
  .navbar8-link5-menu-list {
    display: none;
  }
}
 
@media(max-width: 479px) {
  .navbar8-navbar-interactive {
    padding: var(--dl-layout-space-unit);
  }
  .navbar8-mobile-menu {
    padding: var(--dl-layout-space-unit);
  }
}
</style>
