var render = function render(){var _vm=this,_c=_vm._self._c;return _c('div',{staticClass:"thq-section-padding"},[_c('div',{staticClass:"features24-container2 thq-section-max-width"},[_c('div',{staticClass:"features24-tabs-menu"},[_c('div',{staticClass:"features24-tab-horizontal1",on:{"click":function($event){_vm.activeTab = 0}}},[_c('div',{staticClass:"features24-divider-container1"},[(_vm.activeTab === 0)?_c('div',{staticClass:"features24-container3"}):_vm._e()]),_c('div',{staticClass:"features24-content1"},[_c('h2',{staticClass:"thq-heading-2"},[_vm._t("feature1Title",function(){return [_vm._m(0)]})],2),_c('span',{staticClass:"thq-body-small"},[_vm._t("feature1Description",function(){return [_vm._m(1)]})],2)])]),_c('div',{staticClass:"features24-tab-horizontal2",on:{"click":function($event){_vm.activeTab = 1}}},[_c('div',{staticClass:"features24-divider-container2"},[(_vm.activeTab === 1)?_c('div',{staticClass:"features24-container4"}):_vm._e()]),_c('div',{staticClass:"features24-content2"},[_c('h2',{staticClass:"thq-heading-2"},[_vm._t("feature2Title",function(){return [_vm._m(2)]})],2),_c('span',{staticClass:"thq-body-small"},[_vm._t("feature2Description",function(){return [_vm._m(3)]})],2)])]),_c('div',{staticClass:"features24-tab-horizontal3",on:{"click":function($event){_vm.activeTab = 2}}},[_c('div',{staticClass:"features24-divider-container3"},[(_vm.activeTab === 2)?_c('div',{staticClass:"features24-container5"}):_vm._e()]),_c('div',{staticClass:"features24-content3"},[_c('h2',{staticClass:"thq-heading-2"},[_vm._t("feature3Title",function(){return [_vm._m(4)]})],2),_c('span',{staticClass:"thq-body-small"},[_vm._t("feature3Description",function(){return [_vm._m(5)]})],2)])])])])])
}
var staticRenderFns = [function (){var _vm=this,_c=_vm._self._c;return _c('div',{staticClass:"features24-fragment2"},[_c('span',{staticClass:"features24-text2"},[_vm._v("Data Streaming Solutions")])])
},function (){var _vm=this,_c=_vm._self._c;return _c('div',{staticClass:"features24-fragment3"},[_c('span',{staticClass:"features24-text3"},[_vm._v(" Tailored streaming solutions to meet the needs of banks and financial institutions ")])])
},function (){var _vm=this,_c=_vm._self._c;return _c('div',{staticClass:"features24-fragment4"},[_c('span',{staticClass:"features24-text4"},[_vm._v("Embedded AI for IoT")])])
},function (){var _vm=this,_c=_vm._self._c;return _c('div',{staticClass:"features24-fragment1"},[_c('span',{staticClass:"features24-text1"},[_vm._v(" Embedded AI for Internet of Things ")])])
},function (){var _vm=this,_c=_vm._self._c;return _c('div',{staticClass:"features24-fragment6"},[_c('span',{staticClass:"features24-text6"},[_vm._v("Agentic Intelligence")])])
},function (){var _vm=this,_c=_vm._self._c;return _c('div',{staticClass:"features24-fragment5"},[_c('span',{staticClass:"features24-text5"},[_vm._v(" Agentic Intelligence for Enhanced Decision Making ")])])
}]

export { render, staticRenderFns }